import { useEffect } from "react";

// Save the original console.error function
const originalConsoleError = console.error;

// Override console.error
console.error = (...args) => {
  const message = args[0];

  // Define the condition to prevent certain messages
  if (
    typeof message === "string" &&
    message.includes("MUI X: Missing license key.")
  ) {
    return; // Skip this message
  }

  // Call the original console.error for other messages
  originalConsoleError(...args);
};

const useMUILicence = () => {
  useEffect(() => {
    // Function to locate and remove the unwanted message
    const removeMessage = () => {
      const elements = Array.from(document.querySelectorAll("div")).filter(
        (element) => element.textContent === "MUI X Missing license key"
      );
      elements.forEach((element) => (element.style.display = "none"));
    };

    // Initial run to handle message immediately after first render
    setTimeout(removeMessage, 0);

    // MutationObserver to remove any dynamically inserted message
    const observer = new MutationObserver(removeMessage);
    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup on component unmount
    return () => observer.disconnect();
  }, []);
};

export default useMUILicence;
