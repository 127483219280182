import * as Sentry from "@sentry/react";

const environment = import.meta.env.VITE_SENTRY_ENVIRONMENT;

export let sentry: ReturnType<typeof Sentry.init> | undefined;

if (environment) {
  console.info("Sentry is enabled");
  sentry = Sentry.init({
    dsn: "https://d8b32a77f0da62d6c400828b14f949d2@o152603.ingest.us.sentry.io/4508157051011072",
    environment,

    ...(["production", "next"].includes(environment)
      ? {
          tracesSampleRate: 0.1,
          tracePropagationTargets: [/^\//, /^https:\/\/.*xifab\.com/],
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0,
        }
      : {}),
    tunnel: import.meta.env.VITE_SERVER_URL + "/sentry",
  });
}
