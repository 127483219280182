import { createTheme } from "@mui/material/styles";
import { frFR } from "@mui/x-data-grid/locales";

export const theme = createTheme(
  {
    palette: {
      primary: { main: "#1965BF" },
      secondary: { main: "#eb2ecf" },
    },
    components: {
      MuiTextField: {
        defaultProps: {
          variant: "standard",
        },
      },
    },
  },
  frFR
);
