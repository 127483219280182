import "core-js/stable"; // It adds all stable polyfills  which add weight to the bundle but it makes sure android works.
import "./sentry";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/fr";

dayjs.extend(isSameOrBefore);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

dayjs.locale("fr");
// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}
