import DeleteIcon from "@mui/icons-material/Delete";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { User } from "@tamtam/api/db";
import { createFileRoute, useRouter } from "@tanstack/react-router";
import { useSnackbar } from "notistack";
import { FormDialog } from "../../../components/form-dialog";
import SectionTitle from "../../../components/section-title";
import StatusIcon from "../../../components/status-icon";
import { trpc } from "../../../services/trpc";
import AddUser from "./-components/add-user";
import EditOrganization from "./-components/edit-organization";
import LinkAdmin from "./-components/link-admin";
import { VariablesSection } from "./-components/variables-section";
import { useMount } from "react-use";
import { useUser } from "../../../hooks/use-user";
import { useEffect } from "react";
import { BackgroundImagePaper } from "./-components/background-image-paper";

export const Route = createFileRoute("/dashboard/organizations/$id")({
  component: ShowOrganization,
});

const typeToLabel = {
  SUPER_ADMIN: "Super Admin",
  ADMIN: "Admin",
  SUPERVISOR: "Superviseur",
  ADVISOR: "Conseiller",
};

function ShowOrganization() {
  const { id: organizationId } = Route.useParams();
  const user = useUser();
  const { data: organization, refetch } =
    trpc.organizations.getSuperAdmin.useQuery({ id: user.organizationId });
  const { mutate: deleteUser } = trpc.users.delete.useMutation();
  const { mutate: unlinkAdmin } = trpc.organizations.unlinkAdmin.useMutation();
  const { enqueueSnackbar } = useSnackbar();
  const selectOrganization = trpc.organizations.select.useMutation();
  const router = useRouter();

  // This avoids the top bar organization to be different from the page selected organization
  useMount(async () => {
    selectOrganization.mutateAsync({
      organizationId,
    });
  });

  useEffect(() => {
    router.navigate({
      to: "/dashboard/organizations/$id",
      params: { id: user.organizationId },
    });
  }, [router, user.organizationId]);

  const onDelete = (user: User) => async () => {
    if (
      !window.confirm(
        `Etes-vous sûr de vouloir supprimer l'utilisateur ${user.firstName} ${user.lastName} ?`
      )
    ) {
      return;
    }
    deleteUser({ id: user.id });
    enqueueSnackbar("L'utilisateur a été supprimé", {
      variant: "success",
    });
  };

  const onUnlinkAdmin = (admin: User) => async () => {
    if (
      !window.confirm(
        `Etes-vous sûr de vouloir retirer les droits d'administrateur de ${admin.firstName} ${admin.lastName} ?`
      )
    ) {
      return;
    }
    unlinkAdmin(
      { organizationId: organizationId, userId: admin.id },
      {
        onSuccess: () => {
          enqueueSnackbar("L'administrateur a été retiré", {
            variant: "success",
          });
          refetch();
        },
        onError: (error) => {
          enqueueSnackbar(error.message || "Une erreur est survenue", {
            variant: "error",
          });
        },
      }
    );
  };

  if (!organization) return <CircularProgress />;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <SectionTitle>
          {organization.name} {organization.config?.label_nom}
        </SectionTitle>
        {organization.onScreenNotificationsEnabled && (
          <Box>
            <Chip label="Appel à l'écran activé" color="primary" />
          </Box>
        )}
        <Box sx={{ display: "flex", gap: 2 }}>
          <BackgroundImagePaper organization={organization} />

          <FormDialog
            label="Editer"
            title="Editer l'organisation"
            render={(close) => (
              <EditOrganization
                organizationId={organization.id}
                onSubmitSuccess={close}
              />
            )}
          />
        </Box>
      </Box>
      <VariablesSection organizationId={organizationId} />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "30px auto 20px auto",
        }}
      >
        <Typography variant="h5">Admins</Typography>
        <FormDialog
          label="Ajouter un admin"
          title="Ajouter un admin"
          render={(close) => (
            <LinkAdmin
              organizationId={organization.id}
              onSubmitSuccess={close}
            />
          )}
        />
      </Box>
      {organization.admins && organization.admins.length > 0 ? (
        <Table sx={{ minWidth: 1000 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Prénom</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organization.admins.map((admin) => (
              <TableRow key={admin.id}>
                <TableCell>{admin.id}</TableCell>
                <TableCell>{admin.firstName}</TableCell>
                <TableCell>{admin.lastName}</TableCell>
                <TableCell>{admin.email}</TableCell>
                <TableCell>
                  {admin.hasMultipleOrganizations && (
                    <IconButton
                      color="secondary"
                      onClick={onUnlinkAdmin(admin)}
                    >
                      <LinkOffIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography>
          Aucun administrateur trouvé pour cette organisation.
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "30px auto 20px auto",
        }}
      >
        <Typography variant="h5">Utilisateurs</Typography>
        <FormDialog
          label="Créer un utilisateur"
          title="Créer un utilisateur"
          render={(close) => (
            <AddUser organizationId={organization.id} onSubmitSuccess={close} />
          )}
        />
      </Box>
      {organization.users && organization.users.length > 0 ? (
        <Table sx={{ minWidth: 1000 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Prénom</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Activé</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organization.users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.firstName}</TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <StatusIcon checked={!!user.passwordHash} />
                </TableCell>
                <TableCell>{typeToLabel[user.type]}</TableCell>
                <TableCell>
                  <IconButton color="secondary" onClick={onDelete(user)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography>
          Aucun utilisateur trouvé pour cette organisation.
        </Typography>
      )}
    </>
  );
}
