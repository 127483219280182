import { ExtendedRecordMap } from "notion-types";
import { NotionRenderer } from "react-notion-x";

import "react-notion-x/src/styles.css";

export default function NotionPage({
  recordMap,
}: {
  recordMap: ExtendedRecordMap;
}) {
  return (
    <NotionRenderer
      fullPage={true}
      disableHeader={true}
      recordMap={recordMap}
    />
  );
}
