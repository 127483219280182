import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Switch,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { trpc } from "../../../../services/trpc";

interface EditOrganizationProps {
  organizationId: string;
  onSubmitSuccess: () => void;
}

const EditOrganization: React.FC<EditOrganizationProps> = ({
  organizationId,
  onSubmitSuccess,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: organization } = trpc.organizations.get.useQuery({
    id: organizationId,
  });
  const updateOrganization = trpc.organizations.update.useMutation();

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: organization?.name || "",
      onScreenNotificationsEnabled:
        organization?.onScreenNotificationsEnabled ?? false,
    },
  });

  const onSubmit = async (values: {
    name: string;
    onScreenNotificationsEnabled: boolean;
  }) => {
    try {
      await updateOrganization.mutateAsync({
        id: organizationId,
        name: values.name,
        onScreenNotificationsEnabled: values.onScreenNotificationsEnabled,
      });
      enqueueSnackbar("L'organisation a été éditée", {
        variant: "success",
      });
      onSubmitSuccess();
      reset();
    } catch (e) {
      setError("name", {
        type: "manual",
        message: (e as Error).message,
      });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ display: "flex", flexDirection: "column", minWidth: 300 }}
    >
      <Controller
        name="name"
        control={control}
        rules={{ required: "Ce champ est requis" }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Nom"
            required
            error={!!errors.name}
            helperText={errors.name?.message || ""}
            margin="normal"
          />
        )}
      />
      <Controller
        name="onScreenNotificationsEnabled"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Switch {...field} checked={field.value} />}
            label="Activer l'appel à l'écran"
            sx={{ marginTop: 1 }}
          />
        )}
      />
      {errors.root && (
        <FormHelperText error>{errors.root.message}</FormHelperText>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        sx={{ marginTop: 2, alignSelf: "center" }}
      >
        Valider
      </Button>
    </Box>
  );
};

export default EditOrganization;
