import "@tamtam/api/prisma/types";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createTRPCReact, httpBatchLink } from "@trpc/react-query";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import { FC, useState } from "react";
import superjson from "superjson";

import type { Router } from "@tamtam/api/router";

// eslint-disable-next-line react-refresh/only-export-components
export const trpc = createTRPCReact<Router>({
  overrides: {
    useMutation: {
      // Invalidate all queries in the react-query cache:
      async onSuccess(opts) {
        await opts.originalFn();
        // Check if invalidation should be prevented
        if (!opts.meta?.preventInvalidation) {
          await opts.queryClient.invalidateQueries();
        }
      },
    },
  },
});

export type RouterInput = inferRouterInputs<Router>;
export type RouterOutput = inferRouterOutputs<Router>;

// Standalone client for use outside of React components
// eslint-disable-next-line
export const trpcStandalone = trpc.createClient({
  links: [
    httpBatchLink({
      url: import.meta.env.VITE_SERVER_URL + "/trpc",
      transformer: superjson,
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: "include",
        });
      },
    }),
  ],
});

export const TRPCProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: import.meta.env.VITE_SERVER_URL + "/trpc",
          transformer: superjson,
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: "include",
            });
          },
        }),
      ],
    })
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
};
