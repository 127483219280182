import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/new-password")({
  validateSearch: (search: Record<string, unknown>) => ({
    reset_token: search.reset_token as string,
  }),
  beforeLoad: async ({ context, search }) => {
    if (context.user) {
      throw redirect({
        to: "/dashboard",
        replace: true,
      });
    }

    if (!search.reset_token) {
      throw redirect({
        to: "/reset-password",
        replace: true,
      });
    }
  },
});
