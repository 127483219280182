import { Box } from "@mui/material";
import { Crisp } from "crisp-sdk-web";
import { useState } from "react";
import { useEffect } from "react";

export function OnScreenNotificationsMarketing() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Box
      sx={{
        bgcolor: "#f5f5f5",
        minHeight: "100vh",
        p: 2,
        margin: "-32px",
        backgroundImage: "url('/texture.svg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        WebkitBackgroundSize: "100px",
      }}
    >
      <Box
        component="img"
        src="/callonscreen.png"
        onClick={() => {
          Crisp.chat.open();
        }}
        sx={{
          width: "100%",
          height: "auto",
          maxWidth: "800px",
          boxShadow: 3,
          display: "block",
          borderRadius: 3,
          margin: "auto",
          mt: 10,
          cursor: "pointer",
          opacity: show ? 1 : 0.2,
          transform: show ? "translateY(0)" : "translateY(-16px)",
          transition: "all 0.3s ease-out",
          "&:hover": {
            boxShadow: 10,
            transform: "translateY(-8px)",
          },
        }}
      />
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Box
          component="a"
          href="/dashboard/conditions-generales"
          sx={{
            color: "grey.700",
            fontSize: "0.8rem",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          Conditions générales
        </Box>
      </Box>
    </Box>
  );
}
