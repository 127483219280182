import { pathToPageId } from "@tamtam/api/lib/notion";
import { createFileRoute, notFound } from "@tanstack/react-router";
import { ExtendedRecordMap } from "notion-types";

import { trpcStandalone } from "../../../services/trpc";
import NotionPage from "./-notion-page";

export const Route = createFileRoute("/dashboard/$path/")({
  loader: async ({ params }) => {
    const path = `/${params.path}`;
    if (!pathToPageId[path]) {
      throw notFound();
    }
    const pageId = pathToPageId[path];
    const recordMap = await trpcStandalone.query("notion.getPage", {
      id: pageId,
    });

    return { recordMap };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { recordMap } = Route.useLoaderData() as {
    recordMap: ExtendedRecordMap;
  };
  return <NotionPage recordMap={recordMap} />;
}
