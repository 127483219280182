import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Snackbar, TextField, Typography } from "@mui/material";
import { AdvisorAttributes, Organization } from "@tamtam/api/db";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import { trpc } from "../../../../services/trpc";
import { uploadFiles } from "../../../../services/upload";
import { UploadFileOutlined } from "@mui/icons-material";
import HandlebarTextField from "../../../../components/handlebar-text-field";

type IFormInputs = Organization["onScreenNotificationsConfig"] & {
  backgroundImageFile?: File;
};

interface OnScreenNotificationsFormProps {
  organization: Organization & {
    advisorAttributes: AdvisorAttributes[];
  };
}

export const OnScreenNotificationsForm: React.FC<
  OnScreenNotificationsFormProps
> = ({ organization }) => {
  const { enqueueSnackbar } = useSnackbar();
  const updateConfig =
    trpc.organizations.updateOnScreenNotificationConfig.useMutation();
  const { data: accessToken } = trpc.auth.readAccessToken.useQuery();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    reset,
    watch,
  } = useForm<IFormInputs>({
    defaultValues: organization.onScreenNotificationsConfig,
  });
  const { dirtyFields } = useFormState({ control });

  const onSubmit = async (values: IFormInputs) => {
    try {
      if (values.backgroundImageFile) {
        const response = await uploadFiles("imageUploader", {
          files: [values.backgroundImageFile],
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        values.background_url = response[0].url;
      }

      await updateConfig.mutateAsync({
        id: organization.id,
        onScreenNotificationsConfig: values,
      });
      enqueueSnackbar(
        "La configuration de l'appel à l'écran a bien été mise à jour",
        {
          variant: "success",
        }
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Une erreur est survenue", { variant: "error" });
    }
  };

  useEffect(() => {
    reset(organization.onScreenNotificationsConfig);
  }, [organization, reset]);

  const renderBackgroundImagePreview = useCallback(() => {
    const backgroundImageFile = watch("backgroundImageFile");
    const backgroundUrl = backgroundImageFile
      ? URL.createObjectURL(backgroundImageFile)
      : organization.onScreenNotificationsConfig.background_url;

    if (backgroundImageFile || backgroundUrl) {
      return (
        <Box
          component="img"
          sx={{ maxHeight: 100, marginBottom: 1 }}
          alt="Aperçu de l'image de fond"
          src={backgroundUrl}
        />
      );
    }
    return null;
  }, [watch, organization.onScreenNotificationsConfig.background_url]);

  const hasUnsavedChanges = Object.keys(dirtyFields).length > 0;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isDirty}
        message="Vous avez des changements non sauvegardés"
      />
      <Typography variant="h6">Configuration de l'appel à l'écran</Typography>
      <Controller
        name="notification_message"
        control={control}
        defaultValue=""
        rules={{ required: "Ce champ est requis" }}
        render={({ field }) => (
          <HandlebarTextField
            {...field}
            allowedAttributes={[
              ...organization.advisorAttributes.map(
                (attribute) => attribute.key
              ),
              "advisor",
              "visitor",
            ]}
            label="Message de notification"
            margin="normal"
            sx={{ width: "100%" }}
            error={!!errors.notification_message}
            helperText={errors.notification_message?.message}
          />
        )}
      />
      <Controller
        name="display_duration"
        control={control}
        defaultValue={0}
        rules={{ required: "Ce champ est requis" }}
        render={({ field }) => (
          <TextField
            {...field}
            type="number"
            label="Durée d'affichage (en secondes)"
            margin="normal"
            sx={{ width: "100%" }}
            error={!!errors.display_duration}
            helperText={errors.display_duration?.message}
            onChange={(e) => {
              field.onChange(Number(e.target.value));
            }}
          />
        )}
      />
      <Controller
        name="marquee"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            label="Message défilant"
            margin="normal"
            sx={{ width: "100%" }}
            error={!!errors.marquee}
            helperText={errors.marquee?.message}
          />
        )}
      />
      <Typography sx={{ mt: 3 }} variant="h6">
        Image de fond
      </Typography>
      <Box
        sx={{
          my: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {renderBackgroundImagePreview()}
        <Controller
          name="backgroundImageFile"
          control={control}
          render={({ field }) => (
            <Button
              variant="outlined"
              component="label"
              startIcon={<UploadFileOutlined />}
              sx={{ alignSelf: "flex-start" }}
            >
              Modifier
              <input
                type="file"
                hidden
                onChange={(e) => field.onChange(e.target.files?.[0])}
              />
            </Button>
          )}
        />
      </Box>
      <LoadingButton
        loading={isSubmitting}
        sx={{ mt: 3 }}
        type="submit"
        variant="contained"
        color="primary"
        disabled={!hasUnsavedChanges || isSubmitting}
      >
        Mettre à jour
      </LoadingButton>
    </Box>
  );
};
