import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  IconButton,
  Menu,
  MenuItem,
  AppBar as MUIAppBar,
  Toolbar,
  Typography,
} from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import React from "react";
import { useUser } from "../hooks/use-user";
import { trpc } from "../services/trpc";
import { theme } from "../theme";
import OrganizationSelector from "./organization-selector";

const typeToLabel = {
  SUPER_ADMIN: "Super Admin",
  ADMIN: "Administrateur",
  ADVISOR: "Conseiller",
  SUPERVISOR: "Superviseur",
} as const;

const AppBar: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const user = useUser();
  const logout = trpc.auth.logout.useMutation({});

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const backgroundColor =
    window.location.hostname.indexOf("tamtam.xifab.com") > -1
      ? theme.palette.primary.main
      : lightBlue[500];

  return (
    <>
      <MUIAppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor,
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <span>
              {user.firstName} {user.lastName}
            </span>
            <Typography variant="caption" component="span">
              {typeToLabel[user.type]}
            </Typography>
          </Typography>

          <OrganizationSelector />
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircleIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem disabled>{user.email}</MenuItem>
            <MenuItem onClick={async () => await logout.mutate()}>
              Se déconnecter
            </MenuItem>
          </Menu>
        </Toolbar>
      </MUIAppBar>
    </>
  );
};

export default AppBar;
