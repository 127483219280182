import { AutoAwesomeOutlined } from "@mui/icons-material";
import {
  Badge,
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const LAST_NEWS_READ_KEY = "last-news-read";
const LATEST_NEWS_DATE = "2024-11-17";
const NEWS_LINK =
  "https://julienvallini.notion.site/Nouveaut-s-13f7ceb133ab80bfa65bc78c0cda1f31";

export function NewsLink() {
  const [showBadge, setShowBadge] = useState(false);

  useEffect(() => {
    const lastReadDate = localStorage.getItem(LAST_NEWS_READ_KEY);
    setShowBadge(
      !lastReadDate || dayjs(lastReadDate).isBefore(dayjs(LATEST_NEWS_DATE))
    );
  }, []);

  const handleClick = () => {
    localStorage.setItem(LAST_NEWS_READ_KEY, new Date().toISOString());
    setShowBadge(false);
  };

  return (
    <ListItem disablePadding>
      <ListItemButton
        component="a"
        href={NEWS_LINK}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        <ListItemIcon>
          <AutoAwesomeOutlined />
        </ListItemIcon>
        <ListItemText
          primary={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              Nouveautés
              {showBadge && (
                <Badge
                  color="secondary"
                  variant="dot"
                  sx={{
                    "@keyframes blink": {
                      "0%": { opacity: 1 },
                      "50%": { opacity: 0.3 },
                      "100%": { opacity: 1 },
                    },
                    "& .MuiBadge-badge": {
                      right: 2,
                      animation: "blink 2s ease-in-out infinite",
                    },
                  }}
                />
              )}
            </Box>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}
