import LoadingButton from "@mui/lab/LoadingButton";
import { enqueueSnackbar } from "notistack";
import { useAsyncFn } from "react-use";
import { RouterOutput, trpc } from "../../../../services/trpc";
import { uploadFiles } from "../../../../services/upload";

export function BackgroundImagePaper({
  organization,
}: {
  organization: RouterOutput["organizations"]["getWithAdvisorAttributes"];
}) {
  const { data: accessToken } = trpc.auth.readAccessToken.useQuery();
  const updateBackgroundImage =
    trpc.organizations.updateBackgroundImage.useMutation();

  const [{ loading }, uploadAndUpdate] = useAsyncFn(
    async (files: FileList | null) => {
      if (files && files.length > 0) {
        const response = await uploadFiles("imageUploader", {
          files: Array.from(files),
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        await updateBackgroundImage.mutateAsync({
          id: organization.id,
          backgroundImage: response[0].url,
        });
        enqueueSnackbar("Les paramètres de la borne ont bien été mis à jour", {
          variant: "success",
        });
      }
    },
    [updateBackgroundImage]
  );

  const onSubmit = (files: FileList | null) => {
    uploadAndUpdate(files).catch((e) => {
      enqueueSnackbar((e as Error).message, { variant: "error" });
    });
  };

  return (
    <>
      <LoadingButton
        loading={loading}
        variant="outlined"
        color="primary"
        onClick={() => document.getElementById("file-input")?.click()}
      >
        Modifier l'image de fond
      </LoadingButton>
      <input
        id="file-input"
        type="file"
        style={{ display: "none" }}
        onChange={(e) => onSubmit(e.target.files)}
      />
    </>
  );
}
