import { VolunteerActivismOutlined } from "@mui/icons-material";
import {
  Badge,
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { trpc } from "../../services/trpc";

const REFERRAL_READ_KEY = "referral-read";

export function ReferralLink() {
  const { mutate: logEvent } = trpc.users.logEvent.useMutation();
  const [showBadge, setShowBadge] = useState(false);

  useEffect(() => {
    const isRead = localStorage.getItem(REFERRAL_READ_KEY);
    setShowBadge(!isRead);
  }, []);

  const handleClick = async () => {
    await logEvent({ type: "CLICKED_REFERRAL_DRAWER_BUTTON" });
    localStorage.setItem(REFERRAL_READ_KEY, new Date().toISOString());
    setShowBadge(false);
  };

  return (
    <ListItem disablePadding>
      <ListItemButton
        component={Link}
        to="/dashboard/parrainage"
        onClick={handleClick}
      >
        <ListItemIcon>
          <VolunteerActivismOutlined />
        </ListItemIcon>
        <ListItemText
          primary={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              Parrainage
              {showBadge && (
                <Badge
                  color="secondary"
                  variant="dot"
                  sx={{
                    "@keyframes blink": {
                      "0%": { opacity: 1 },
                      "50%": { opacity: 0.3 },
                      "100%": { opacity: 1 },
                    },
                    "& .MuiBadge-badge": {
                      right: 2,
                      animation: "blink 2s ease-in-out infinite",
                    },
                  }}
                />
              )}
            </Box>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}
