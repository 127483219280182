import { invert } from "lodash";

export const pathToPageId: Record<string, string> = {
  "/parrainage": "13f7ceb133ab80bfa65bc78c0cda1f31",
  "/conditions-generales": "1377ceb133ab805ca441f7644787afb3",
};

export const pageIdToPath = invert(pathToPageId);

export const getNotion = async () => {
  const { NotionAPI } = await import("notion-client");
  return new NotionAPI();
};
