/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as NewPasswordImport } from './routes/new-password'
import { Route as LoginImport } from './routes/login'
import { Route as DashboardImport } from './routes/dashboard'
import { Route as IndexImport } from './routes/index'
import { Route as DashboardParrainageIndexImport } from './routes/dashboard/parrainage/index'
import { Route as DashboardAppelEcranIndexImport } from './routes/dashboard/appel-ecran/index'
import { Route as DashboardPathIndexImport } from './routes/dashboard/$path/index'
import { Route as DashboardOrganizationsIdImport } from './routes/dashboard/organizations/$id'

// Create Virtual Routes

const ResetPasswordLazyImport = createFileRoute('/reset-password')()
const ScreenIdLazyImport = createFileRoute('/screen/$id')()
const LiveIdLazyImport = createFileRoute('/live/$id')()
const DashboardWaitingIndexLazyImport = createFileRoute('/dashboard/waiting/')()
const DashboardOrganizationsIndexLazyImport = createFileRoute(
  '/dashboard/organizations/',
)()
const DashboardFlowIndexLazyImport = createFileRoute('/dashboard/flow/')()
const DashboardArchiveIndexLazyImport = createFileRoute('/dashboard/archive/')()
const DashboardAdvisorsIndexLazyImport = createFileRoute(
  '/dashboard/advisors/',
)()

// Create/Update Routes

const ResetPasswordLazyRoute = ResetPasswordLazyImport.update({
  id: '/reset-password',
  path: '/reset-password',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/reset-password.lazy').then((d) => d.Route),
)

const NewPasswordRoute = NewPasswordImport.update({
  id: '/new-password',
  path: '/new-password',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/new-password.lazy').then((d) => d.Route))

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/login.lazy').then((d) => d.Route))

const DashboardRoute = DashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const ScreenIdLazyRoute = ScreenIdLazyImport.update({
  id: '/screen/$id',
  path: '/screen/$id',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/screen/$id.lazy').then((d) => d.Route))

const LiveIdLazyRoute = LiveIdLazyImport.update({
  id: '/live/$id',
  path: '/live/$id',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/live/$id.lazy').then((d) => d.Route))

const DashboardWaitingIndexLazyRoute = DashboardWaitingIndexLazyImport.update({
  id: '/waiting/',
  path: '/waiting/',
  getParentRoute: () => DashboardRoute,
} as any).lazy(() =>
  import('./routes/dashboard/waiting/index.lazy').then((d) => d.Route),
)

const DashboardOrganizationsIndexLazyRoute =
  DashboardOrganizationsIndexLazyImport.update({
    id: '/organizations/',
    path: '/organizations/',
    getParentRoute: () => DashboardRoute,
  } as any).lazy(() =>
    import('./routes/dashboard/organizations/index.lazy').then((d) => d.Route),
  )

const DashboardFlowIndexLazyRoute = DashboardFlowIndexLazyImport.update({
  id: '/flow/',
  path: '/flow/',
  getParentRoute: () => DashboardRoute,
} as any).lazy(() =>
  import('./routes/dashboard/flow/index.lazy').then((d) => d.Route),
)

const DashboardArchiveIndexLazyRoute = DashboardArchiveIndexLazyImport.update({
  id: '/archive/',
  path: '/archive/',
  getParentRoute: () => DashboardRoute,
} as any).lazy(() =>
  import('./routes/dashboard/archive/index.lazy').then((d) => d.Route),
)

const DashboardAdvisorsIndexLazyRoute = DashboardAdvisorsIndexLazyImport.update(
  {
    id: '/advisors/',
    path: '/advisors/',
    getParentRoute: () => DashboardRoute,
  } as any,
).lazy(() =>
  import('./routes/dashboard/advisors/index.lazy').then((d) => d.Route),
)

const DashboardParrainageIndexRoute = DashboardParrainageIndexImport.update({
  id: '/parrainage/',
  path: '/parrainage/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardAppelEcranIndexRoute = DashboardAppelEcranIndexImport.update({
  id: '/appel-ecran/',
  path: '/appel-ecran/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardPathIndexRoute = DashboardPathIndexImport.update({
  id: '/$path/',
  path: '/$path/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardOrganizationsIdRoute = DashboardOrganizationsIdImport.update({
  id: '/organizations/$id',
  path: '/organizations/$id',
  getParentRoute: () => DashboardRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/dashboard': {
      id: '/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/new-password': {
      id: '/new-password'
      path: '/new-password'
      fullPath: '/new-password'
      preLoaderRoute: typeof NewPasswordImport
      parentRoute: typeof rootRoute
    }
    '/reset-password': {
      id: '/reset-password'
      path: '/reset-password'
      fullPath: '/reset-password'
      preLoaderRoute: typeof ResetPasswordLazyImport
      parentRoute: typeof rootRoute
    }
    '/live/$id': {
      id: '/live/$id'
      path: '/live/$id'
      fullPath: '/live/$id'
      preLoaderRoute: typeof LiveIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/screen/$id': {
      id: '/screen/$id'
      path: '/screen/$id'
      fullPath: '/screen/$id'
      preLoaderRoute: typeof ScreenIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/dashboard/organizations/$id': {
      id: '/dashboard/organizations/$id'
      path: '/organizations/$id'
      fullPath: '/dashboard/organizations/$id'
      preLoaderRoute: typeof DashboardOrganizationsIdImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/$path/': {
      id: '/dashboard/$path/'
      path: '/$path'
      fullPath: '/dashboard/$path'
      preLoaderRoute: typeof DashboardPathIndexImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/appel-ecran/': {
      id: '/dashboard/appel-ecran/'
      path: '/appel-ecran'
      fullPath: '/dashboard/appel-ecran'
      preLoaderRoute: typeof DashboardAppelEcranIndexImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/parrainage/': {
      id: '/dashboard/parrainage/'
      path: '/parrainage'
      fullPath: '/dashboard/parrainage'
      preLoaderRoute: typeof DashboardParrainageIndexImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/advisors/': {
      id: '/dashboard/advisors/'
      path: '/advisors'
      fullPath: '/dashboard/advisors'
      preLoaderRoute: typeof DashboardAdvisorsIndexLazyImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/archive/': {
      id: '/dashboard/archive/'
      path: '/archive'
      fullPath: '/dashboard/archive'
      preLoaderRoute: typeof DashboardArchiveIndexLazyImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/flow/': {
      id: '/dashboard/flow/'
      path: '/flow'
      fullPath: '/dashboard/flow'
      preLoaderRoute: typeof DashboardFlowIndexLazyImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/organizations/': {
      id: '/dashboard/organizations/'
      path: '/organizations'
      fullPath: '/dashboard/organizations'
      preLoaderRoute: typeof DashboardOrganizationsIndexLazyImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/waiting/': {
      id: '/dashboard/waiting/'
      path: '/waiting'
      fullPath: '/dashboard/waiting'
      preLoaderRoute: typeof DashboardWaitingIndexLazyImport
      parentRoute: typeof DashboardImport
    }
  }
}

// Create and export the route tree

interface DashboardRouteChildren {
  DashboardOrganizationsIdRoute: typeof DashboardOrganizationsIdRoute
  DashboardPathIndexRoute: typeof DashboardPathIndexRoute
  DashboardAppelEcranIndexRoute: typeof DashboardAppelEcranIndexRoute
  DashboardParrainageIndexRoute: typeof DashboardParrainageIndexRoute
  DashboardAdvisorsIndexLazyRoute: typeof DashboardAdvisorsIndexLazyRoute
  DashboardArchiveIndexLazyRoute: typeof DashboardArchiveIndexLazyRoute
  DashboardFlowIndexLazyRoute: typeof DashboardFlowIndexLazyRoute
  DashboardOrganizationsIndexLazyRoute: typeof DashboardOrganizationsIndexLazyRoute
  DashboardWaitingIndexLazyRoute: typeof DashboardWaitingIndexLazyRoute
}

const DashboardRouteChildren: DashboardRouteChildren = {
  DashboardOrganizationsIdRoute: DashboardOrganizationsIdRoute,
  DashboardPathIndexRoute: DashboardPathIndexRoute,
  DashboardAppelEcranIndexRoute: DashboardAppelEcranIndexRoute,
  DashboardParrainageIndexRoute: DashboardParrainageIndexRoute,
  DashboardAdvisorsIndexLazyRoute: DashboardAdvisorsIndexLazyRoute,
  DashboardArchiveIndexLazyRoute: DashboardArchiveIndexLazyRoute,
  DashboardFlowIndexLazyRoute: DashboardFlowIndexLazyRoute,
  DashboardOrganizationsIndexLazyRoute: DashboardOrganizationsIndexLazyRoute,
  DashboardWaitingIndexLazyRoute: DashboardWaitingIndexLazyRoute,
}

const DashboardRouteWithChildren = DashboardRoute._addFileChildren(
  DashboardRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRouteWithChildren
  '/login': typeof LoginRoute
  '/new-password': typeof NewPasswordRoute
  '/reset-password': typeof ResetPasswordLazyRoute
  '/live/$id': typeof LiveIdLazyRoute
  '/screen/$id': typeof ScreenIdLazyRoute
  '/dashboard/organizations/$id': typeof DashboardOrganizationsIdRoute
  '/dashboard/$path': typeof DashboardPathIndexRoute
  '/dashboard/appel-ecran': typeof DashboardAppelEcranIndexRoute
  '/dashboard/parrainage': typeof DashboardParrainageIndexRoute
  '/dashboard/advisors': typeof DashboardAdvisorsIndexLazyRoute
  '/dashboard/archive': typeof DashboardArchiveIndexLazyRoute
  '/dashboard/flow': typeof DashboardFlowIndexLazyRoute
  '/dashboard/organizations': typeof DashboardOrganizationsIndexLazyRoute
  '/dashboard/waiting': typeof DashboardWaitingIndexLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRouteWithChildren
  '/login': typeof LoginRoute
  '/new-password': typeof NewPasswordRoute
  '/reset-password': typeof ResetPasswordLazyRoute
  '/live/$id': typeof LiveIdLazyRoute
  '/screen/$id': typeof ScreenIdLazyRoute
  '/dashboard/organizations/$id': typeof DashboardOrganizationsIdRoute
  '/dashboard/$path': typeof DashboardPathIndexRoute
  '/dashboard/appel-ecran': typeof DashboardAppelEcranIndexRoute
  '/dashboard/parrainage': typeof DashboardParrainageIndexRoute
  '/dashboard/advisors': typeof DashboardAdvisorsIndexLazyRoute
  '/dashboard/archive': typeof DashboardArchiveIndexLazyRoute
  '/dashboard/flow': typeof DashboardFlowIndexLazyRoute
  '/dashboard/organizations': typeof DashboardOrganizationsIndexLazyRoute
  '/dashboard/waiting': typeof DashboardWaitingIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRouteWithChildren
  '/login': typeof LoginRoute
  '/new-password': typeof NewPasswordRoute
  '/reset-password': typeof ResetPasswordLazyRoute
  '/live/$id': typeof LiveIdLazyRoute
  '/screen/$id': typeof ScreenIdLazyRoute
  '/dashboard/organizations/$id': typeof DashboardOrganizationsIdRoute
  '/dashboard/$path/': typeof DashboardPathIndexRoute
  '/dashboard/appel-ecran/': typeof DashboardAppelEcranIndexRoute
  '/dashboard/parrainage/': typeof DashboardParrainageIndexRoute
  '/dashboard/advisors/': typeof DashboardAdvisorsIndexLazyRoute
  '/dashboard/archive/': typeof DashboardArchiveIndexLazyRoute
  '/dashboard/flow/': typeof DashboardFlowIndexLazyRoute
  '/dashboard/organizations/': typeof DashboardOrganizationsIndexLazyRoute
  '/dashboard/waiting/': typeof DashboardWaitingIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/dashboard'
    | '/login'
    | '/new-password'
    | '/reset-password'
    | '/live/$id'
    | '/screen/$id'
    | '/dashboard/organizations/$id'
    | '/dashboard/$path'
    | '/dashboard/appel-ecran'
    | '/dashboard/parrainage'
    | '/dashboard/advisors'
    | '/dashboard/archive'
    | '/dashboard/flow'
    | '/dashboard/organizations'
    | '/dashboard/waiting'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/dashboard'
    | '/login'
    | '/new-password'
    | '/reset-password'
    | '/live/$id'
    | '/screen/$id'
    | '/dashboard/organizations/$id'
    | '/dashboard/$path'
    | '/dashboard/appel-ecran'
    | '/dashboard/parrainage'
    | '/dashboard/advisors'
    | '/dashboard/archive'
    | '/dashboard/flow'
    | '/dashboard/organizations'
    | '/dashboard/waiting'
  id:
    | '__root__'
    | '/'
    | '/dashboard'
    | '/login'
    | '/new-password'
    | '/reset-password'
    | '/live/$id'
    | '/screen/$id'
    | '/dashboard/organizations/$id'
    | '/dashboard/$path/'
    | '/dashboard/appel-ecran/'
    | '/dashboard/parrainage/'
    | '/dashboard/advisors/'
    | '/dashboard/archive/'
    | '/dashboard/flow/'
    | '/dashboard/organizations/'
    | '/dashboard/waiting/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  DashboardRoute: typeof DashboardRouteWithChildren
  LoginRoute: typeof LoginRoute
  NewPasswordRoute: typeof NewPasswordRoute
  ResetPasswordLazyRoute: typeof ResetPasswordLazyRoute
  LiveIdLazyRoute: typeof LiveIdLazyRoute
  ScreenIdLazyRoute: typeof ScreenIdLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  DashboardRoute: DashboardRouteWithChildren,
  LoginRoute: LoginRoute,
  NewPasswordRoute: NewPasswordRoute,
  ResetPasswordLazyRoute: ResetPasswordLazyRoute,
  LiveIdLazyRoute: LiveIdLazyRoute,
  ScreenIdLazyRoute: ScreenIdLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/dashboard",
        "/login",
        "/new-password",
        "/reset-password",
        "/live/$id",
        "/screen/$id"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/dashboard": {
      "filePath": "dashboard.tsx",
      "children": [
        "/dashboard/organizations/$id",
        "/dashboard/$path/",
        "/dashboard/appel-ecran/",
        "/dashboard/parrainage/",
        "/dashboard/advisors/",
        "/dashboard/archive/",
        "/dashboard/flow/",
        "/dashboard/organizations/",
        "/dashboard/waiting/"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/new-password": {
      "filePath": "new-password.tsx"
    },
    "/reset-password": {
      "filePath": "reset-password.lazy.tsx"
    },
    "/live/$id": {
      "filePath": "live/$id.lazy.tsx"
    },
    "/screen/$id": {
      "filePath": "screen/$id.lazy.tsx"
    },
    "/dashboard/organizations/$id": {
      "filePath": "dashboard/organizations/$id.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/$path/": {
      "filePath": "dashboard/$path/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/appel-ecran/": {
      "filePath": "dashboard/appel-ecran/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/parrainage/": {
      "filePath": "dashboard/parrainage/index.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/advisors/": {
      "filePath": "dashboard/advisors/index.lazy.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/archive/": {
      "filePath": "dashboard/archive/index.lazy.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/flow/": {
      "filePath": "dashboard/flow/index.lazy.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/organizations/": {
      "filePath": "dashboard/organizations/index.lazy.tsx",
      "parent": "/dashboard"
    },
    "/dashboard/waiting/": {
      "filePath": "dashboard/waiting/index.lazy.tsx",
      "parent": "/dashboard"
    }
  }
}
ROUTE_MANIFEST_END */
