import { Box, Toolbar } from "@mui/material";
import * as Sentry from "@sentry/react";
import {
  createFileRoute,
  Outlet,
  redirect,
  useNavigate,
} from "@tanstack/react-router";
import { useEffect } from "react";
import AppBar from "../components/app-bar";
import { Drawer } from "../components/drawer";
import { useCrisp } from "../hooks/use-crisp";
import { useUser } from "../hooks/use-user";
import { useWebSocket } from "../hooks/use-websocket";

const DashboardLayout = () => {
  useWebSocket();
  useCrisp();
  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    if (!user) {
      Sentry.setUser(null);

      navigate({
        to: "/login",
        search: {
          redirect: "",
        },
      });
    }
  }, [user, navigate]);

  // Just during the navigation
  if (!user) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", minHeight: "100vh", minWidth: "100vw" }}>
      <AppBar />
      <Drawer />
      <Box
        component="main"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Toolbar />
        <Box
          sx={{
            p: 4,
            width: "100%",
            flex: 1,
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export const Route = createFileRoute("/dashboard")({
  component: DashboardLayout,
  beforeLoad: async ({ context, location }) => {
    if (!context.user) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
        replace: true,
      });
    }
    if (["/dashboard", "/dashboard/"].includes(location.pathname)) {
      throw redirect({
        to: "/dashboard/waiting",
        replace: true,
      });
    }
  },
});
