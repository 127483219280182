import { useAtom } from "jotai";
import { useEffect } from "react";
import { io, Socket } from "socket.io-client";

import { websocketAtom } from "../atoms/websocketAtom";
import { trpc } from "../services/trpc";

let socket: Socket | null = null;

export function connect() {
  if (socket) {
    socket.disconnect();
  }

  socket = io(import.meta.env.VITE_SERVER_URL, {
    withCredentials: true,
  });

  socket.on("connect", () => {
    console.info("Socket is open");
  });

  socket.on("error", (error) => {
    console.error("Socket encountered error", error);
  });

  socket.on("disconnect", () => {
    console.info("Socket is closed");
  });

  return socket;
}

export const useWebSocket = () => {
  const [, setWebSocket] = useAtom(websocketAtom);
  const { data: user } = trpc.auth.me.useQuery();

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    const ws = connect();

    setWebSocket(ws);

    return () => {
      ws.disconnect();
      setWebSocket(null);
    };
  }, [setWebSocket, user?.id]);
};
