import { createFileRoute } from "@tanstack/react-router";
import { useUser } from "../../../hooks/use-user";
import { trpc } from "../../../services/trpc";
import { OnScreenNotificationsMarketing } from "./-components/on-screen-notifications-marketing";
import { OnScreenNotificationsForm } from "./-components/on-screen-notifications-form";

export const Route = createFileRoute("/dashboard/appel-ecran/")({
  component: RouteComponent,
});

function RouteComponent() {
  const user = useUser();
  const { data: organization } =
    trpc.organizations.getWithAdvisorAttributes.useQuery({
      id: user.organizationId,
    });

  if (!organization) {
    return null;
  }

  if (!organization.onScreenNotificationsEnabled) {
    return <OnScreenNotificationsMarketing />;
  }

  return <OnScreenNotificationsForm organization={organization} />;
}
